import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ModalDirective } from "@node_modules/ngx-bootstrap/modal";
import {
    CreateOrEditReportDashboardDto,
    DashboardMode,
    DashboardToTurbines,
    DashboardType,
    PowerPlantsServiceProxy,
    PowerPlantType,
    PowerPlantUnitsServiceProxy,
    ReportDashboardServiceProxy
} from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DateTime } from "luxon";
import { finalize } from "@node_modules/rxjs/operators";
import { Router } from "@angular/router";
import { DateTimeService } from "@app/shared/common/timing/date-time.service";
import { LocalStorageService } from "@shared/utils/local-storage.service";

class CreateDashboardDto {
    name = "My New Dashboard";
    type: DashboardType;
    mode: DashboardMode;
    content: [];
}

export class DashboardContentDto {
    Id: number;
    Title: string;
    IsSelected: boolean;
    Name: string;
    PowerPlantId: number;
}

@Component({
    selector: "createDashboardModal",
    templateUrl: "./create-dashboard.component.html",
    styleUrls: ["./create-dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None
})

export class CreateDashboardComponent extends AppComponentBase implements OnInit {

    protected readonly DashboardType = DashboardType;
    protected readonly DashboardMode = DashboardMode;

    @ViewChild("createModal", { static: true }) modal: ModalDirective;
    @Output() saveDashboard: EventEmitter<any> = new EventEmitter<any>();

    saving = false;
    tabIndex: number = 0;
    createDashboardDto = new CreateDashboardDto();
    dashboardContents: DashboardContentDto[] = [];
    dashboardContent = new DashboardContentDto();
    dashboardToTurbines: DashboardToTurbines[] = [];
    filterText = "";
    numberEmpty: number;
    dateEmpty: DateTime;
    tabs: { id: number, number: number, title: string, description: string } [] = [
        {
            id: 0,
            number: 1,
            title: this.l("DashboardMode"),
            description: this.l("CreateDashboardModeStepDescription")
        },
        {
            id: 1,
            number: 2,
            title: this.l("DashboardType"),
            description: this.l("CreateDashboardTypeStepDescription")
        },
        {
            id: 2,
            number: 3,
            title: this.l("DashboardContent"),
            description: this.l("CreateDashboardContentStepDescription")
        }

    ];
    public tenancyName: any;

    public readonly TENANT_SabanciRenewables = "sabancict";

    constructor(
        injector: Injector,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
        private _powerPlantsServiceProxy: PowerPlantsServiceProxy,
        private _powerPlantUnitsServiceProxy: PowerPlantUnitsServiceProxy,
        private _router: Router,
        private _dateTimeService: DateTimeService,
        private _localStorageService: LocalStorageService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.clear();

        this._localStorageService.getItem("tenancyName", (err, tName) => {
            this.tenancyName = tName;
        });
    }

    clear() {
        this.tabIndex = 0;
        this.saving = false;
        this.filterText = "";
        this.dashboardContents = [];
        this.createDashboardDto = new CreateDashboardDto();
    }

    show(): void {
        this.clear();
        this.modal.show();
    }

    close(): void {
        this.saving = true;
        this.clear();
        this.modal.hide();
    }

    public setActiveTab(index: number) {
        if (this.createDashboardDto.mode != null) {
            if (index == 0 || index == 1) {
                this.tabIndex = index;
            }
        }

        if (this.createDashboardDto.type != null) {
            if (index == 2) {
                this.tabIndex = index;
                if (this.createDashboardDto.type == DashboardType.PowerPlant) {
                    this.getPowerPlants();
                } else if (this.createDashboardDto.type == DashboardType.Generator) {
                    this.getTurbines();
                }
            }
        }
    }

    changeType(type: DashboardType) {
        this.createDashboardDto.type = type;
        // this.setActiveTab(1)
    }

    changeMode(mode: DashboardMode) {
        this.createDashboardDto.mode = mode;
        // this.setActiveTab(2)
    }

    getPowerPlants() {
        this.dashboardContents = [];
        this._powerPlantsServiceProxy.getAll(
            "",
            this.filterText,
            -1,
            this.numberEmpty,
            this.numberEmpty,
            "",
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            "",
            this.dateEmpty,
            this.dateEmpty,
            "",
            this.dateEmpty,
            this.dateEmpty,
            "",
            "id",
            0,
            100
        ).subscribe(result => {
            this.dashboardContents = [];
            result.items = result.items.filter(x => x.powerPlant.type != PowerPlantType.Hybrid);
            for (let item of result.items) {
                this.dashboardContents.push({
                    Id: item.powerPlant.id,
                    Title: item.powerPlant.name,
                    IsSelected: false,
                    Name: item.powerPlant.name,
                    PowerPlantId: 0
                });
            }
        });
    }

    getTurbines() {
        this._powerPlantUnitsServiceProxy.getAll(
            "",
            this.filterText,
            -1,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            this.numberEmpty,
            "",
            this.numberEmpty,
            this.numberEmpty,
            this.dateEmpty,
            this.dateEmpty,
            "",
            this.dateEmpty,
            this.dateEmpty,
            "",
            "",
            "id",
            0,
            100
        ).subscribe(result => {
            this.dashboardContents = [];
            for (let item of result.items) {
                this.dashboardContents.push({
                    Id: item.powerPlantUnit.id,
                    Title: item.powerPlantUnit.name + " - " + item.powerPlantName,
                    IsSelected: false,
                    Name: item.powerPlantUnit.name.replace(" ", "") + "-" + item.powerPlantName.replace(" ", ""),
                    PowerPlantId: item.powerPlantUnit.powerPlantId
                });
            }
        });
    }

    getContents() {
        if (this.createDashboardDto.type == DashboardType.PowerPlant) {
            this.getPowerPlants();
        } else if (this.createDashboardDto.type == DashboardType.Generator) {
            this.getTurbines();
        }
    }

    resetFilter() {
        this.filterText = "";
        this.getContents();
    }

    changeSelection(item) {
        this.dashboardContents.forEach(val => {
            if (val.Id == item.Id) val.IsSelected = !val.IsSelected;
            else {
                val.IsSelected = false;
            }
        });
    }

    createDashboard() {
        this.dashboardContent = this.dashboardContents.find(x => x.IsSelected);
        if (this.dashboardContent) {
            this.saving = true;
            let model = new CreateOrEditReportDashboardDto();
            model.name = this.createDashboardDto.name;

            if (this.createDashboardDto.type == DashboardType.Generator) {
                this.dashboardContents.forEach(val => {
                    if (val.IsSelected) {
                        model.powerPlantId = val.PowerPlantId;
                    }
                });
                model.isTurbineDashboard = true;

            } else {
                model.isTurbineDashboard = false;
                model.powerPlantId = this.dashboardContent.Id;
            }


            model.type = this.createDashboardDto.type;
            model.mode = this.createDashboardDto.mode;

            model.status = 0;
            model.shareType = 0;

            this._reportDashboardServiceProxy.createOrEditReportDashboard(model)
                .pipe(finalize(() => {
                    this.saving = false;
                }))
                .subscribe((data) => {

                    if (this.createDashboardDto.type == DashboardType.Generator) {
                        this.dashboardContents.forEach(val => {
                            if (val.IsSelected) {
                                let dashboardToTurbine = new DashboardToTurbines();
                                dashboardToTurbine.dashboardId = data;
                                dashboardToTurbine.turbineId = val.Id;
                                this.dashboardToTurbines.push(dashboardToTurbine);
                            }
                        });

                        this._reportDashboardServiceProxy.saveDashboardToTurbines(this.dashboardToTurbines)
                            .subscribe((data2) => {
                                this.notify.info(this.l("SavedSuccessfully"));
                                this.modal.hide();
                                this.saveDashboard.emit();
                                this._router.navigate([`app/main/dashboard/${data}`]);
                            });
                    } else {
                        this.notify.info(this.l("SavedSuccessfully"));
                        this.modal.hide();
                        this.saveDashboard.emit();
                        this._router.navigate([`app/main/dashboard/${data}`]);
                    }
                });
        }
    }
}
