import { ChangeDetectorRef, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { DataGridColumnData, DataGridColumnType, DataGridData } from "./DataGridModels";
import { PowerPlantType } from "@shared/service-proxies/service-proxies";
import { UnitStatusClasses } from "@app/main/portfolio/portfolio.const";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { StringValuesConst } from "@app/main/report-trials/tables/string-values/string-values.const";

@Component({
    selector: "data-grid",
    templateUrl: "./data-grid.component.html",
    styleUrls: ["./data-grid.component.scss"]
})
export class DataGridComponent implements OnInit, OnChanges {
    protected readonly DataGridColumnType = DataGridColumnType;
    @Input() data: DataGridData;
    @Input() sortingIndex?: number;
    @Input() divId: string;
    @Input() tableItemLimit?: number;
    tables: DataGridData[] = [];

    @Input() callbackFunction: (args: any, asd: any) => void;

    constructor(injector: Injector, private _changeDetectorReference: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.data) {
            this.setSorting();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["data"]) {
            this.setSorting();
        }
    }

    setSorting() {
        if (this.sortingIndex === undefined || this.sortingIndex === null) {
            if (this.data) {
                this.data.rows.filter((x) => (x.columns = x.columns.filter((x) => x.visible == true)));
                this.data.headers = this.data.headers.filter((x) => x.visible == true);
                const index = this.data.headers.findIndex((x) => x.sorting);
                if (index == -1) {
                    this.sortingIndex = this.data.headers.findIndex((x) => x.sortable);
                } else {
                    this.sortingIndex = index;
                }

                this.sort(this.sortingIndex);
            }
        } else {
            this.sort(this.sortingIndex);
        }
    }

    sort(columnIndex: number) {
        if (columnIndex != null || columnIndex != undefined) {
            this.sortingIndex = columnIndex;

            this.data.rows = this.data.rows.sort((row1, row2) => {
                let firstValue = row1.columns[columnIndex].value;
                let secondValue = row2.columns[columnIndex].value;

                // Sayısal karşılaştırma için Number() kullanıyoruz
                let firstNumber = Number(firstValue);
                let secondNumber = Number(secondValue);

                // Geçerli sayılar olup olmadığını kontrol edin (NaN değilse)
                if (!isNaN(firstNumber) && !isNaN(secondNumber)) {
                    return firstNumber - secondNumber;
                }

                // Eğer sayısal değer değilse, string karşılaştırmaya dön
                firstValue = firstValue != null ? String(firstValue) : '';
                secondValue = secondValue != null ? String(secondValue) : '';
                return firstValue.localeCompare(secondValue);
            });


            this.data.headers.forEach((header, index) => {
                header.sorting = index == columnIndex;
            });
            this.setTables();
            this._changeDetectorReference.detectChanges();
        }
    }

    setTables() {
        if (this.tableItemLimit) {
            let limit = 0;
            let tablesIndex = 0;
            this.tables[0] = { headers: [], rows: [] };


            let grouped = this.data.rows.reduce(function(columns, row) {

                row.columns.forEach(function(column) {
                    let name = column.modelId;
                    let rowData = columns[name] || (columns[name] = []);

                    rowData.push(row);
                });
                return columns;
            }, []);


            grouped.forEach((_item) => {
                _item.forEach((item) => {
                    if (
                        this.tables[tablesIndex].rows.filter(
                            (x) => x.columns[0].type === 0 && x.columns[0].value.toString() === item.columns[0].value.toString()
                        ).length === 0
                    )
                        this.tables[tablesIndex].rows.push(item);
                    if (limit == _item.length - 1) {
                        limit = 0;
                        tablesIndex++;
                        this.tables[tablesIndex] = { headers: [], rows: [] };
                    } else {
                        limit++;
                    }
                });
            });
            for (let i = 0; i < this.tables.length; i++) {
                if (this.tables[i].rows.length == 0) this.tables.splice(i, 1);
            }
            this.tables.forEach((t) => (t.headers = this.data.headers));
        } else {
            this.tables[0] = this.data;
        }

        let n = this.tables.length;
        let arr = this.tables;
        // Dış döngü, sıralanacak eleman sayısı kadar çalışır
        for (let i = 0; i < n; i++) {
            // İç döngü, her seferinde yan yana iki elemanı karşılaştırır
            for (let j = 0; j < n - 1; j++) {
                if (arr[j].rows[0]?.columns[0]?.nominalPower) {
                    if (arr[j].rows[0].columns[0].nominalPower > arr[j + 1].rows[0].columns[0].nominalPower) {
                        // Elemanların yerini değiştir (swap)
                        let temp = arr[j];
                        arr[j] = arr[j + 1];
                        arr[j + 1] = temp;
                    }
                }
            }
        }

        setTimeout(() => {
            abp.event.trigger(this.divId, {
                isCompleted: true,
                highChart: undefined,
                type: DashboardItemWidgetType.table
            });
        }, 2000);
    }

    getUnitStatusColor(onePactStatus: number, powerPlantType: PowerPlantType) {
        if (onePactStatus != null || onePactStatus != undefined) return UnitStatusClasses[powerPlantType][onePactStatus];
    }

    // Function that returns the appropriate color code based on a given value and maximum value
    getColorForInverterPrValue(column: DataGridColumnData) {
        if (column.name == "Performance Ratio") {
            // Convert the value's range 0-maxValue to range 0-4
            let index = Math.floor(column.value / (100 / 4));

            // Check minimum and maximum values
            if (index < 0) {
                index = 0;
            } else if (index > 4) {
                index = 4;
            }

            //Return color code
            return StringValuesConst.REALTIME_CELL_COLOR[index];
        }

        if (column.name && column.name.includes("Solar Tracker Motor Status")) {

            if (column.value == "error") {
                return  "#FF0000";
            }
            else {
                return "#32CD32";
            }
        }
    }

    // getSolarTrackerMotorStatusColor(column: DataGridColumnData) {
    //
    // }
}
